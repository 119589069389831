<template>
	<div class="navmb">
		<div class="flex flexa">
			<el-icon class="astyle bgshadow" color="#FF6A00" size="20" @click="$router.push({
				name: 'tech/list',
				params: {
					type: 1
				}
			})">
				<Back />
			</el-icon> &nbsp; &nbsp;
			<span class="">新建非技术服务请求</span>
		</div>
	</div>
	<ul class="ul1 ul-stepb1">
		<li :class="step >= 1 ? 'act' : ''">
			<span class="num">1</span>
			<p>问题描述/严重性</p>
			<div class="line"></div>
		</li>
		<li :class="step >= 2 ? 'act' : ''">
			<span class="num">2</span>
			<p>智能推荐解决方案</p>
			<div class="line"></div>
		</li>
		<li :class="step >= 3 ? 'act' : ''">
			<span class="num">3</span>
			<p>详细资料</p>
			<div class="line"></div>
		</li>
		<li :class="step >= 4 ? 'act' : ''">
			<span class="num">4</span>
			<p>联系方式</p>
		</li>
	</ul>
	<div v-if="step == 1" v-loading="loading" class="bgf padd20">
		<el-form :model="baseInfo.form" ref="submitForm_baseInfo" label-width="auto" :rules="baseInfo.rule">
			<p class="col24 font18 fontw5 marb24">问题发生在什么地方？</p>
			<ul class="ul-form marb30 clearfix">

				<li>
					<div class="con">
						<div class="ty"><em>*</em>请求类型</div>
						<div class="right w255">
							<el-form-item prop="productId">
								<el-select size="large" v-model="baseInfo.form.productId" value-key="id"
									placeholder="请选择请求类型" @change="$event => {
				baseInfo.form.productName = $event.productName
				vselect_module($event.id);
			}">
									<el-option v-for="item in vselect.product.options" :key="item.id"
										:label="item.productName" :value="item" />
								</el-select>
							</el-form-item>
						</div>
					</div>
				</li>
				<li>
					<div class="con">
						<div class="ty"><em>*</em>问题类型</div>
						<div class="right w255">
							<el-form-item prop="productModuleId">
								<el-cascader v-model="baseInfo.form.productModuleId" size="large" class="w100" :props="{
				checkStrictly: false,
				emitPath: true,
				value: 'id',
				label: 'moduleName',
				children: 'moduleListDtos'
			}" :options="moduleMapList" placeholder="请选择" @change="productModuleIdChange">
								</el-cascader>
								<!-- <el-select
									v-model="baseInfo.form.productModuleId"
									value-key="id"
									size="large"
									placeholder="请选择"
									@change="$event => {
										baseInfo.form.moduleName = $event.moduleName
										searchQuestionTemplate({ productId: baseInfo.form.productId.id,productModuleId: $event.id })
									}"
								>
									<el-option
										v-for="item in moduleMapList"
										:key="item.id"
										:label="item.moduleName"
										:value="item"
									>
									</el-option>
								</el-select> -->
							</el-form-item>
						</div>
					</div>
				</li>
				<li style="display: block">&nbsp;</li>
				<li>
					<div class="con">
						<div class="ty">客户服务号</div>
						<div class="right">
							<div class="w255">
								<el-form-item prop="csi">
									<el-select size="large" v-model="baseInfo.form.csi" placeholder="请选择" clearable
										@change="csiChange" @clear="csiClear">
										<el-option v-for="(item, index) in csiList" :key="index"
											:label="item.csiNumber + '（' + item.companyName + '）'" :value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
							<div class="tips">
								温馨提示：非技术请求不是必须要选择客户服务号。
							</div>
						</div>
					</div>
				</li>
			</ul>
			<p class="col24 font18 fontw5 marb24">问题内容描述</p>
			<div class="flex">
				<div class="flex5">
					<ul class="sylform labw120 padt30 padb30">
						<li>
							<label for=""><em>*</em>问题概要</label>
							<el-form-item prop="problemProfile">
								<el-input v-model="baseInfo.form.problemProfile" placeholder="请输入问题的概要" class="w620"
									size="large"></el-input>
							</el-form-item>
						</li>
						<li class="li1">
							<label for=""><em>*</em>请输入问题描述</label>
							<div class="imgli1">
								<div class="font14 w620" style="box-sizing: border-box; z-index: 500;">
									<kindeditor :uploadParams="{
				type: 3,
			}" :content="baseInfo.form.problemDescription" width="700px" height="400px"
										@base64Html="base64Flie($event, 'baseInfo', 'problemDescription', 'form')"
										id="kind2"
										@on-content-change="onContentChange($event, 'baseInfo', 'problemDescription', 'form')">
									</kindeditor>
									<!-- <Toolbar style="border-bottom: 1px solid #dfe2e8" :editor="editorRef"
											mode="default" />
										<Editor class="w100" style="height: 400px; overflow-y: hidden" v-model="baseInfo.form.problemDescription"
											:defaultConfig="editorConfig" mode="default" @onCreated="$event => editorRef = $event" /> -->
									<el-form-item prop="problemDescription">
									</el-form-item>
								</div>
							</div>
						</li>
						<li class="li1 mart20">
							<label for="">错误代码</label>
							<div>
								<el-form-item prop="errorCode">
									<el-input maxlength="300" v-model="baseInfo.form.errorCode" type="textarea" :rows="2"
										placeholder="请输入程序中错误代码" class="w620" size="large"></el-input>
								</el-form-item>
								<br />
								<p class="col8f padt10 font14">请输入数据库、程序中报错的错误代码</p>
							</div>
						</li>
					</ul>
					<template v-if="baseInfo.form.csi">
						<p class="col24 font18 fontw5 marb24">团队内工单查阅权限</p>
						<ul class="ul-form clearfix sty1">
							<li>
								<div class="con">
									<div class="ty"><em>*</em>是否全部公开</div>
									<div class="right">
										<div class="m-radsb1">
											<el-form-item prop="isOpen">
												<el-radio-group v-model="baseInfo.form.isOpen">
													<el-radio :value="1">是</el-radio>
													<el-radio :value="2">否</el-radio>
												</el-radio-group>
											</el-form-item>
										</div>
									</div>
								</div>
							</li>
							<li v-if="baseInfo.form.isOpen == 2">
								<div class="con">
									<div class="ty"><em>*</em> 公开的团队部门</div>
									<div class="right">
										<el-form-item prop="departmentId">
											<el-select v-model="baseInfo.form.departmentId" size="large" multiple
												collapse-tags :max-collapse-tags="2" collapse-tags-tooltip
												placeholder="请选择" style="width: 340px">
												<el-option
													v-for="item in vselect.team.map_options.get(baseInfo.form.csi)"
													:key="item.id" :label="item.departmentTitle" :value="item.id" />
											</el-select>
										</el-form-item>
									</div>
								</div>
							</li>
						</ul>
					</template>
				</div>
			</div>
		</el-form>
		<ul class="sylform labw120">
			<li class="padt20">
				<label for=""></label>
				<div>
					<el-button class="w90" size="large" type="primary" :loading="checkLoading"
						@click="submitBaseStep()">下一步</el-button>
					<el-button class="w68" size="large" @click="celall">取消</el-button>
				</div>
			</li>
		</ul>
	</div>

	<div v-if="step == 2" v-loading="loading" class="bgf padd20 padt30">
		<p class="font19 padb30">问题类型：{{ computed_moduleText }}</p>
		<p class="font16 bl-tipb1">该类型的问题推荐解决方案的知识文档</p>
		<div v-if="knowDoc.list.length > 0">
			<ul class="padb10 padt20">
				<li v-for="(item, index) in knowDoc.list" :key="index" class="padb14">
					<el-link :href="'#/knowledgeInfo/' + item.id" target="_blank">{{ index + 1 }}. {{ item.docTitle
						}}</el-link>
				</li>
			</ul>
			<div class="h20"></div>
			<el-pagination v-model:currentPage="knowDoc.param.pageNumber" v-model:page-size="knowDoc.param.pageSize"
				@update:page-size="getknowDocList" @update:current-page="getknowDocList" :page-sizes="[5, 10, 15, 20]"
				layout=" prev, pager, next, sizes, jumper" :total="knowDoc.total">
			</el-pagination>
		</div>

		<div v-else class="inbl padl40">
			<el-empty description="暂无相关文档"></el-empty>
		</div>

		<div class="padt40 padb20">
			<el-button class="w90" @click="step = 1" size="large">上一步</el-button>
			<el-button class="w90" type="primary" @click="step = 3; Object.assign(baseInfo.form, {
				mainContact: userInfo.username,
				mainMobile: userInfo.mobile,
				mainEmail: userInfo.email,
			})" size="large">下一步</el-button>
			<el-button class="w68" @click="celall" size="large">取消</el-button>
		</div>
	</div>

	<div v-if="step == 3" class="bgf padd20">
		<p class="col24 font16 padb10">问题类型：{{ computed_moduleText }}</p>
		<el-form :model="baseInfo.form" ref="submitForm_Attachment" label-width="auto" :rules="baseInfo.rule">
			<ul class="sylform">
				<li class="li1">
					<label for="" class="wauto2">问题附件</label>
					<div>
						<el-form-item prop="attachmentPath">
							<ul class="ulfile padb10">
								<li v-for="(item, index) in fileList.attachmentPath" :key="index" class="">
									<span class="col63">{{ item.name }}</span>
									<span @click="() => {
				fileList.attachmentPath.splice(index, 1);
				baseInfo.form['attachmentPath'] = files.map(item => item.img).filter(item => item).join(',')
			}" class="colred point">删除</span>
								</li>
							</ul>
						</el-form-item>

						<div class="positionr inbl">
							<el-upload v-model:file-list="fileList.attachmentPath"
								:ref="el => fileList['instance_attachmentPath'] = el" :headers="{
				token: $store.state.token,
			}" :data="{
				type: 3
			}" action="/api/user/upload/ossUploadList" class="upload-demo" multiple :limit="5" :show-file-list="false"
								name="multipartFile" :on-success="(resp, file, files) => {
				if (resp.status == 200) {
					file.img = utils.Decrypt(resp.data).path;
					baseInfo.form['attachmentPath'] = files.map(item => item.img).filter(item => item).join(',')
				}
			}">
								<el-button type="primary" size="large">添加附件</el-button>
							</el-upload>
						</div>
						<p class="col8f font12 padt4">
							支持.png、.jpg、.jpeg、.txt、.rar、.doc、.xls、.xlsx、.zip格式，最大不超过50M
						</p>
					</div>
				</li>
				<li class="li1">
					<label for="" class="wauto2">附件注释</label>
					<el-form-item prop="attachmentDescription">
						<el-input v-model="baseInfo.form.attachmentDescription" type="textarea" :rows="6"
							placeholder="请输入添加附件的注释" class="w515" size="large"></el-input>
					</el-form-item>
				</li>
				<li class="li1 padt40">
					<label for="" style="opacity: 0" class="wauto2 colf">附件注释</label>
					<div class="padb20">
						<el-button class="w90" @click="step = 2" size="large">上一步</el-button>
						<el-button class="w90" type="primary" @click="submitAttachmentStep" size="large">下一步</el-button>
						<el-button class="w68" @click="celall" size="large">取消</el-button>
					</div>
				</li>
			</ul>
		</el-form>
	</div>

	<div v-if="step == 4" class="bgf padd20">
		<p class="col24 font16 padb30">系统与谁联系并了解工单的详细信息</p>
		<el-form ref="ruleForm" :model="baseInfo.form" :rules="baseInfo.rule" hide-required-asterisk
			class="demo-ruleForm" label-width="117px">
			<el-form-item prop="vs_conid">
				<template #label>
					<label for="" class="font12 col8f padr14">常用联系人选择<em class="colred">*</em></label>
				</template>
				<el-select size="large" class="w240" v-model="baseInfo.form.vs_conid" placeholder="请选择" value-key="id"
					@change="$event => {
				baseInfo.form.mainContact = $event.username;
				baseInfo.form.mainMobile = $event.mobile;
				baseInfo.form.mainEmail = $event.email;
			}">
					<el-option v-for="(item, index) in vselect.userContacts.options" :label="item.username"
						:value="item" :key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="mainContact">
				<template #label>
					<label for="" class="font12 col8f padr14">联系人姓名<em class="colred">*</em></label>
				</template>
				<el-input v-model="baseInfo.form.mainContact" placeholder="请输入联系人姓名" class="w240"
					size="large"></el-input>
			</el-form-item>
			<el-form-item prop="mainMobile">
				<template #label>
					<label for="" class="font12 col8f padr14">手机号<em class="colred">*</em></label>
				</template>
				<el-input v-model="baseInfo.form.mainMobile" placeholder="请输入手机号" class="w240" size="large"></el-input>
			</el-form-item>

			<el-form-item prop="mainEmail">
				<template #label>
					<label for="" class="font12 col8f padr14">邮箱号<em class="colred">*</em></label>
				</template>
				<el-input v-model="baseInfo.form.mainEmail" placeholder="请输入邮箱号" class="w240" size="large"></el-input>
				<!-- <el-checkbox-group v-model="list">
					<el-checkbox
                        :disabled="item.readonly??false"
                        :value="item.value"
                        :label="item.label"
                    />
				</el-checkbox-group> -->
			</el-form-item>

			<el-form-item prop="mode">
				<template #label>
					<label for="" class="font12 col8f padr14">联系方式<em class="colred">*</em></label>
				</template>
				<el-radio-group v-model="baseInfo.form.mode">
					<el-radio :value="1" label="电子邮件" />
					<el-radio :value="2" label="电话" />
				</el-radio-group>
			</el-form-item>
		</el-form>

		<div class="padb20 padt20" style="padding-left: 117px">
			<el-button class="w90" @click="step = 3" size="large">上一步</el-button>
			<el-button class="w90" type="primary" size="large" @click="submit">提交工单</el-button>
			<el-button class="w68" @click="celall" size="large">取消</el-button>
		</div>
	</div>
</template>
<script>
import { shallowRef } from 'vue'
import OSS from 'ali-oss'
import { mapGetters, mapState } from 'vuex'
import '@wangeditor/editor/dist/css/style.css'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
//图标
import { Back } from '@element-plus/icons-vue'
import { uploadOssGetToken } from "@/utils/api1.js"

export default {
	components: {
		Back,
		// Editor, Toolbar
	},
	computed: {
		...mapGetters({
			csiList: 'cache/getCsiList',
		}),
		...mapState({
			userInfo: state => state.userinfo
		}),
		moduleMapList() {
			return this.vselect.module.map_options.get(this.baseInfo.form.productId?.id)
		},
		computed_moduleText() {
			const form = this.baseInfo.form
			return `${form.productName}/${form.moduleName}`
		}
	},
	data() {
		return {
			editorRef: shallowRef(),
			editorConfig: {
				placeholder: '请输入内容...',
			},
			step: 1,
			checkLoading: false,
			loading: false,
			param: {
				problemDesImagePath: [], //图片地址
				attachmentPath: [],   //附件地址
				workOrderType: 2,
				problemSeverity: 3,
			},
			knowDoc: {
				_param: {},
				param: {
					docTitle: '',
					pageNumber: 1,
					pageSize: 10,
				},
				list: [],
				total: 0,
				loading: false
			},
			productName: {}, //产品名称item
			operationSystem: {}, //操作系统
			operationSystemVersion: {}, //操作系统->版本
			problemType: {}, //问题类型
			productNameVersion: null,
			// Added
			baseInfo: {
				isSubmit: true,
				form: {
					oneMachine: 1,
					type: 1,
					problemDescription: "",
					isOpen: 1,
					mode: [],
					departmentId: [],
				},
				rule: {
					productId: [{ required: true, message: '请选择请求类型！', trigger: 'change' }],
					productModuleId: [{ required: true, message: '请选择问题类型！', trigger: 'change' }],
					problemProfile: [{ required: true, message: '请输入问题概要！', trigger: 'blur' }],
					problemDescription: [{
						validator: (rule, value, callback) => {
							if (value) {
								return callback()
							}
							return callback(new Error('请输入问题描述！'))
						}
					}],
					isOpen: [{ required: true, message: '请选择是否公开！', trigger: 'change' }],
					departmentId: [
						{
							validator: (rule, value, callback) => {
								if (value.length) {
									return callback()
								}
								return callback(new Error('请选择团队部门'))
							}, trigger: 'change'
						},
					],
					// 联系人校验
					mainContact: [{ required: true, message: '请输入联系人姓名！', trigger: 'blur' }],
					mainMobile: [
						{ validator: this.utils.checkMobile, required: true, trigger: 'blur' },
						{ required: true, message: '请输入手机号', trigger: 'blur' }
					],
					mainEmail: [

						{
							validator: (rule, value, callback) => {
								// console.log(value);
								const reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
								if (!value) {
									callback();
								} else if (!reg.test(value)) {
									callback(new Error("当前输入邮箱号有误，请核对后再次进行输入"));
								} else {
									callback();
								}
							}, required: true, trigger: 'blur'
						},
						{ required: true, message: '请输入邮箱', trigger: 'blur' }
					],
					mode: [
						{ required: true, message: '请选择方式', trigger: 'change' }
					],

				}
			},
			vselect: {
				product: {
					loading: false,
					options: [],
					map_options: new Map(),
				},
				module: {
					loading: false,
					map_options: new Map(),
					map_module_text: new Map(),
				},
				team: {
					loading: false,
					map_options: new Map()
				},
				userContacts: {
					options: []
				}
			},
			fileList: {
				instance_attachmentPath: null,
				attachmentPath: [],
			}
		}
	},
	mounted() {
		this.$store.dispatch('cache/getCsiList')
		this.vselect_product()
	},
	beforeUnmount() {
		// this.editorRef.destroy()
	},
	methods: {
		base64Flie(base64, key1, key2, key3) {
			if (key3) {
				this[key1][key3][key2] += base64
			} else {
				this[key1][key2] += base64
			}
		},
		onContentChange(val, key1, key2, key3) {
			if (val.includes(';base64')) {
				let that = this
                let start = `<img src="data:`
				let end = `" alt="" />`
				const regex = new RegExp(`${start}(.*?)${end}`);
				const result = regex.exec(val);
                const substr = result ? ('data:'+result[1]) : ""; 
				console.log('截取', substr);
				uploadOssGetToken().then(res1 => {
					console.log('获取oss参数', res1)
					if (res1) {
						that.uploadToOSS(res1, substr, key1, key2, key3,val.split(substr)[0],val.split(substr)[1])
					}
				});
			} else if (key3) {
				this[key1][key3][key2] = val
			} else {
				this[key1][key2] = val
			}
		},

		
		async uploadToOSS(data, url, key1, key2, key3,p1,p2) {
			let that = this
			console.log('获取图片路径', url)
			function dataURLtoBlob(dataurl) {
				const arr = dataurl.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new Blob([u8arr], { type: mime });
			}
			const blob = dataURLtoBlob(url)
			const date = new Date();
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			const filePath = `uploads${year}${month}${day}`;
			let file = new File([blob], filePath, { type: blob.type });
			console.log('filefilefile', file)
			let client = new OSS({
				region: 'oss-cn-beijing',
				secure: true,
				accessKeyId: data.ossAccessKeyId,
				accessKeySecret: data.accessKeySecret,
				stsToken: data.securityToken,
				endpoint: "oss-cn-beijing.aliyuncs.com",
				bucket: 'bucket-mvs'
			});
			try {
				console.log('文件', file)
				//上传
				let result = await client.multipartUpload(Date.parse(new Date()) / 1000 + filePath, file, {});
				console.log('上传成功返回', result)
				let url = p1 + result.res.requestUrls[0].split('?uploadId=')[0] + p2
				if (key3) {
					that[key1][key3][key2] = url
				} else {
					that[key1][key2] = url
				}
				// that.file.url = result.res.requestUrls[0]
				// that.fileList.attachment['attachmentPath'] = result.res.requestUrls[0]
			} catch (e) {
				console.log('失败', e)
				if (e.code === 'ConnectionTimeoutError') {
					content.onError('视频上传超时')
					throw "视频上传超时!";
				} else {
					content.onError('视频上传失败')
				}
			}
		},
		async vselect_team(id) {
			try {
				this.vselect.team.loading = true
				this.vselect.team.map_options.set(
					id,
					this.vselect.team.map_options.get(id) ?? (
						await this.$api.user.getDepartmentListByCsiId({
							id,
							pageNumber: 1,
							pageSize: 2000
						})
					).records
				)
				this.vselect.team.loading = false
			} catch (e) {
				this.vselect.team.loading = false
				this.vselect.team.options = []
			}
		},
		csiChange(e) {
			this.baseInfo.form.departmentId = [];
			// 
			// this.baseInfo.form.productId = undefined
			// this.baseInfo.form.versionId = undefined
			// this.baseInfo.form.productModuleId = undefined
			// 
			this.vselect_team(e);
		},
		csiClear() {
			this.baseInfo.form.departmentId = []
		},
		async vselect_product() {
			try {
				this.vselect.product.loading = true
				const { records, total } = await this.$api.bug.productList({
					type: 2,
					pageNumber: 1,
					pageSize: 1500
				})
				this.vselect.product.map_options = new Map(
					records.map(item => [item.id, item])
				)
				this.vselect.product.options = records
				this.vselect.product.loading = false
			} catch (e) {
				this.vselect.product.loading = false
				this.vselect.product.options = []
			}
		},
		productModuleIdChange(e) {
			if (e) {
				this.baseInfo.form.moduleName = e.map(item => this.vselect.module.map_module_text.get(item)).join('/')
				this.searchQuestionTemplate({
					productId: this.baseInfo.form.productId.id,
					productModuleId: e[e.length - 1]
				})
			}
		},
		async vselect_module(id) {
			try {
				this.vselect.module.loading = true
				this.baseInfo.form.productModuleId = undefined
				this.vselect.module.map_options.set(
					id,
					this.vselect.module.map_options.get(id) ?? (
						await this.$api.bug.moduleList({
							id,
							pageNumber: 1,
							pageSize: 2000
						})
					).records
				)
				// 构建文字id映射
				this.vselect.module.map_options.get(id).map(item => {
					this.vselect.module.map_module_text.set(item.id, item.moduleName)
					item.moduleListDtos.map(item => {
						this.vselect.module.map_module_text.set(item.id, item.moduleName)
					})
				})
				this.vselect.module.loading = false
			} catch (e) {
				this.vselect.module.loading = false
				this.vselect.module.options = []
			}
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		},
		celall() {
			this.$router.push('/tech/list/1')
			return
			this.$confirm('退出编辑后，您编辑的内容将会被清除请提前做好编辑内容的备份哦', '您确定要退出编辑当前的工单么？', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.$router.go(-1)
			}).catch(() => {

			});
		},
		async submitBaseStep() {
			try {
				await this.$refs['submitForm_baseInfo'].validate()
				this.checkLoading = true
				// if (this.baseInfo.form.csi) {
				// 	const { status } = await this.$api.order.checkProductReq({
				// 		productId: this.baseInfo.form.productId.id,
				// 		productModuleId: this.baseInfo.form.productModuleId.id,
				// 		csi: this.baseInfo.form.csi,
				// 	})
				// 	if (status == 0) {
				// 		return this.$message.warning('该csi下没有此产品')
				// 	}
				// }
				this.knowDoc.param.docTitle = this.baseInfo.form.problemProfile
				this.getknowDocList()
				this.step = 2
			} catch (e) {
				return console.log(e)
			} finally {
				this.checkLoading = false
			}
		},
		async getknowDocList() {
			try {
				this.knowDoc.loading = true
				let param = JSON.parse(JSON.stringify(this.knowDoc.param))
				delete param.docTitle
				const { records, total } = await this.$api.know.getDocLists(param, { docTitle: this.knowDoc.docTitle })
				this.knowDoc.list = records
				this.knowDoc.total = total
			} catch (e) {
				return console.log(e)
			} finally {
				this.knowDoc.loading = false
			}
		},
		async submitAttachmentStep() {
			try {
				await this.$refs['submitForm_Attachment'].validate()
				this.step = 4
				// 获取常用联系人
				this.getUserContacts()
			} catch (e) {
				return console.log(e)
			}
		},
		async getUserContacts() {
			try {
				const result = await this.$api.order.getUserContacts({
					id: this.baseInfo.form.csi
				})
				this.vselect.userContacts.options = result
			} catch (e) {
				return console.log(e)
			} finally {

			}
		},
		async submit() {
			try {
				await this.$refs['ruleForm'].validate()
				await this.$confirm('工单提交后 平台对应的客服工程师将会为您解决对应的问题 请耐心等待', '您确定提交当前的工单么？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				})
				const _data = JSON.parse(JSON.stringify(this.baseInfo.form))
				delete _data.problemDescription
				delete _data.problemProfile
				delete _data.errorCode

				_data.productId = _data.productId.id
				_data.productModuleId = _data.productModuleId[_data.productModuleId.length - 1]
				_data.mode = _data.mode
				if (!_data.csi) {
					delete _data.isOpen
					delete _data.departmentId
				} else {
					_data.departmentId = _data.departmentId.join(',')
				}
				console.log(_data, '提交')
				const result = await this.$api.order.subOrder(_data, {
					problemDescription: this.baseInfo.form.problemDescription,
					problemProfile: this.baseInfo.form.problemProfile,
					errorCode:this.baseInfo.form.errorCode
				})
				if (result) {
					this.baseInfo.isSubmit = false
					this.$router.replace({
						name: 'serviceInfo',
						params: {
							id: result.id
						}
					})
					this.$message.success(result.message)
				}
				console.log(result, 'result')
			} catch (e) {
				return console.log(e)
			} finally {

			}
		},
		async searchQuestionTemplate(param) {
			try {
				const result = await this.$api.base.searchQuestionTemplate(param)
				this.baseInfo.form.problemDescription = result.content
				console.log(result, 'result')
			} catch (e) {
				return console.log(e)
			}
		},
	},
	beforeRouteLeave(to, form, next) {
		if (this.baseInfo.isSubmit) {
			this.$confirm('退出编辑后，您编辑的内容将会被清除请提前做好编辑内容的备份哦', '您确定要退出编辑当前的工单么？', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				next()
			}).catch(() => {
				next(false)
			});
		} else {
			next()
		}

	}
}
</script>
<style lang="scss" scoped>
.ul-stepb1 {
	.line {
		width: 55px;
		height: 1px;
		background-color: #e2e2e4;
		margin: 0 10px;
	}
}

.ul1 {
	display: flex;
	height: 66px;
	font-size: 17px;
	color: #808080;

	li {
		display: flex;
		align-items: center;

		.num {
			width: 26px;
			height: 26px;
			background: #fff;
			display: flex;
			justify-content: center;
			line-height: 26px;
			border-radius: 50%;
			font-size: 16px;
			margin-right: 12px;
		}
	}

	>.act {
		color: #ff6a00;

		.num {
			background: #ff6a00;
			color: #fff;
		}
	}
}

.bl-tipb1 {
	padding-left: 14px;
	position: relative;
	margin-bottom: 10px;

	&::before {
		content: "";
		display: block;
		width: 4px;
		height: 16px;
		background: #ff6a00;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -8px;
	}
}

.ul-form {
	font-size: 0;

	li {
		display: inline-block;
		vertical-align: top;
	}

	.con {
		margin: 0 20px 12px;
	}

	.ty {
		float: left;
		min-width: 110px;
		font-size: 14px;
		color: #8f8f8f;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		line-height: 40px;
		text-align: right;
		margin-right: 24px;

		em {
			font-style: normal;
			padding-right: 4px;
			color: #e1504a;
		}
	}

	.right {
		overflow: hidden;
	}

	.tips {
		font-size: 14px;
		color: #969799;
	}

	&.sty1 {
		li {
			display: block;
			width: auto;
		}

		.con {
			margin: 0 0 30px;
		}
	}
}

.v-radio-group {
	display: block;
}
</style>